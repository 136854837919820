<template>
<div class="footer">
    <div class="flex1">
        <div class="grid1">
            <img src="../assets/logo.png" alt="" width="150px"  class="logo">
        
        </div>


        <div class="sec2">
            <hr>
            <div>Dopawell © 2023 / All Rights Reserved</div>
        </div>
    </div>
</div>
       
</template>


<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
@import url('http://fonts.cdnfonts.com/css/gotham');

*{
    font-family: 'Gotham', sans-serif;
}

.footer{
    width: 100vw;
    height: 350px;
    background-color: #EDF3F9;
    padding: 100px;
}

.grid1{
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

.flex2{
    display: flex;
    flex-direction: column;
}

.flex2-text1{
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
}

.flex2-text2{
    font-size: 14px;
     margin-top: 10px;
     color: black;
     text-decoration: none;
     font-weight: 500;
}

.flex2-text2:hover{
    color: #00ad14;
}

.grid2{
    display: flex;
    flex-direction: row;
}

.sec2{
    padding-top: 30px;
    font-weight: 500;
}

.social-icon{
    width: 30px;
    height: 30px;
    margin-right: 30px;
    color: black;
    cursor: pointer;
}


@media screen and (max-width: 800px) {

    .footer{
    width: 100vw;
    height: 200px;
    background-color: #EDF3F9;
    padding: 40px;
}

.grid1{
    display: flex;
    flex-direction: column;
}

.flex2{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.grid2{
    margin-top: 20px;
}

}

</style> 