<template>

<div class="main">
  <div class="topbar">
  <!-- logo -->
  <router-link to="/">
        <img src="../assets/logo.png" alt="" width="120px" height=""  class="logo">
  </router-link>
  <!-- navigation links -->
  <div class="nav">
    <router-link to="/" class="nav-link">Home</router-link>
    <!-- <router-link to="/about" class="nav-link">About</router-link> -->
    <!-- <router-link to="/support" class="nav-link">Support</router-link> -->
    <router-link to="/faq" class="nav-link">FAQs</router-link>
  </div>
  <!-- waitlist button -->
     <a href="https://98zh7mjvgx0.typeform.com/to/inAW1YKg" class="btn1">
     <span class="btn-text">Get Started</span>
     </a>
</div>

<div class="topbar-mob">
    <!-- logo -->
  <router-link to="/">
        <img src="../assets/logo.png" alt="" width="120px" class="logo">
        <!-- width="150px" height="47.5" -->
  </router-link>

     <font-awesome-icon
        :icon="['fas', 'bars']"
        class="bars-icon"
        v-b-toggle.sidebar-right
      />

</div>
    <b-sidebar id="sidebar-right" title="" right shadow >
      <div class="nav-mob">
        <router-link to="/" class="nav-link-mob">Home</router-link>
        <!-- <router-link to="/about" class="nav-link-mob">About</router-link> -->
        <!-- <router-link to="/support" class="nav-link">Support</router-link> -->
        <router-link to="/faq" class="nav-link-mob">FAQs</router-link>

         <a href="https://s68gvei6dz3.typeform.com/to/TH2Sphz0" class="btn1">
     <span class="btn-text">Get Started</span>
     </a>
          </div>
     
    </b-sidebar>

       
</div>


</template>


<script>
export default {
  name: "Navbar",
};
</script>

<style scoped>
@import url('http://fonts.cdnfonts.com/css/gotham');

*{
  font-family: 'Gotham' sans-serif;
  /* font-family: 'Raleway', sans-serif; */
}

.main{
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  position: sticky;
  top: 0;
  background-color: #ffffff80;
  z-index: 5;
  height: 70px;
}

.topbar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.topbar-mob{ 
  display: none;
}

.logo{

  margin-top: 25px;
  margin-left: 105px;
  /* margin: 100px; */
}

.nav{
  display: flex;
  flex-direction: row;
  height: 40px;
  margin-top: 10px;
}

.nav-link{
  color: black;
  margin-top: 10px;
  font-weight: 500;
  width: 45px;
  margin-left: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav-link:hover{
  color: #00ad14;
  margin-top: 10px;
}

.nav-link.router-link-exact-active {
  color: #00ad14;
   border-bottom: 3px solid #00ad14;
   cursor: pointer;
 }

.btn1{
  background-color: #00ad14;
  color: white;
  height: 48px;
  width: 161px;
  margin: 10px;
  margin-top: 15px;
  margin-right: 50px;
  border-radius: 8px;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-decoration: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.btn-text{
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;

}

.btn1:hover{
 color: #00ad14;
 border: 0.5px solid #00ad14;
 background-color: white;
}


.btn-text{
  text-align: center;
  line-height: 20px;
  font-weight: 500;
}

.Drawer{
  background-color: blue;
}

@media screen and (max-width: 800px) {

.topbar{
  display: none;
}

.logo{
  margin-top: 25px;
  margin-left: 25px;
}

.topbar-mob{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bars-icon{
  margin: 20px;
  font-size: 20px;
  outline: none;
}

.nav-mob{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;

 
}

.nav-link-mob{
  color: black;
  margin-top: 40px;
  font-weight: 500;
   margin-top: 30px;
  
}

.nav-link-mob:hover{
  color: #00ad14;
}

.nav-link-mob.router-link-exact-active {
  color: #00ad14;
   cursor: pointer; 
 }

 .btn1{
 margin: 0 auto;
 margin-top: 60px;
}

}

</style>
