var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-sec"},[_c('div',{staticClass:"hero"},[_c('div',{staticClass:"hero-info"},[_c('h1',{staticClass:"hero-info-text1"},[_vm._v("Path to "),_c('span',{staticClass:"blue",attrs:{"id":"blue"}},[_c('vue-typer',{staticClass:"vue-typer",staticStyle:{"color":"blue"},attrs:{"text":_vm.profAnim,"type-delay":120,"erase-delay":"70"}})],1),_vm._v(" "),_c('br'),_vm._v(" through "),_c('strong',[_vm._v("Therapy")])]),_c('span',{staticClass:"hero-info-text2"},[_vm._v("Connect with licensed therapists from the comfort of your home. Discover a convenient and confidential way to prioritize your mental health and well-being. Your journey to a happier, healthier you starts here. ")]),_vm._m(0)]),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"sec2"},[_c('h1',{staticClass:"sec2-text1"},[_vm._v("HOW DOPAWELL WORKS")]),(_vm.showProf1)?_c('div',{staticClass:"sec2-main-grid profs"},[_vm._m(3),_vm._m(4),_vm._m(5)]):_vm._e(),(_vm.showFac1)?_c('div',{staticClass:"sec2-main-grid facs"},[_vm._m(6),_vm._m(7),_vm._m(8)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn1",attrs:{"href":"https://98zh7mjvgx0.typeform.com/to/inAW1YKg"}},[_c('span',{staticClass:"btn1-text"},[_vm._v("Get Started")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero-images"},[_c('div',{staticClass:"image-flex"},[_c('img',{attrs:{"src":require("../assets/group1.png"),"alt":"","width":"403px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero-images-mob"},[_c('img',{staticClass:"mob-image",attrs:{"src":require("../assets/group1.png"),"alt":"","width":"300px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sec2-main-grid-item"},[_c('div',{staticClass:"sec2-main-grid-text1"},[_vm._v(" Personalized Assessment")]),_c('span',{staticClass:"sec2-main-grid-text2"},[_vm._v("Fill out a customized assessment to pinpoint your unique requirements.")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sec2-main-grid-item"},[_c('div',{staticClass:"sec2-main-grid-text1"},[_vm._v("Assessment Analysis")]),_c('span',{staticClass:"sec2-main-grid-text2"},[_vm._v("Our system carefully analyzes your assessment to determine the most suitable therapy approach for your individual needs.")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sec2-main-grid-item"},[_c('div',{staticClass:"sec2-main-grid-text1"},[_vm._v(" Match with Your Preferred Therapist")]),_c('span',{staticClass:"sec2-main-grid-text2"},[_vm._v("Connect with your chosen therapist and start your sessions.2 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sec2-main-grid-item"},[_c('div',{staticClass:"sec2-main-grid-text1"},[_vm._v("Sign Up")]),_c('span',{staticClass:"sec2-main-grid-text2"},[_vm._v("Create your profile, customize your dashboard and manage your preferences. ")]),_vm._v(" "),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sec2-main-grid-item"},[_c('div',{staticClass:"sec2-main-grid-text1"},[_vm._v("Search Candidates")]),_c('span',{staticClass:"sec2-main-grid-text2"},[_vm._v("Search for verified professionals in the pool based on profession, location, years of experience, urgency etc.")]),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sec2-main-grid-item"},[_c('div',{staticClass:"sec2-main-grid-text1"},[_vm._v("Hire a Professional")]),_c('span',{staticClass:"sec2-main-grid-text2"},[_vm._v("Chat with candidates, schedule and conduct your video interviews all on our platform and hire a professional. ")])])
}]

export { render, staticRenderFns }