<template>
<div>
    <Navbar />
  <div>
    <!-- <font-awesome-icon :icon="['fas', 'user']" class="user_icon" /> -->
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view />
      </transition>
  </div>
      <Footer />
</div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";


export default {
  name: "",
  components: {
    Navbar,
    Footer
  },
};
</script>


<style>
@import url('http://fonts.cdnfonts.com/css/gotham');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

</style>
