import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhone, faHome, faFile, faDatabase, faAddressBook, faQuestion, faPowerOff, faPencilAlt, faGift, faPlus, faSmile, faUser, faArrowRight, faQuestionCircle, faForward, faCheck, faClipboard, faWallet, faClock, faBriefcase, faMoneyBill, faUsers, faServer, faCaretDown, faBars, faEnvelope, faLocationArrow, faBell, faAngleDown, faCircle, faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
// animation on scroll library used here
import AOS from 'aos'
import 'aos/dist/aos.css'


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueTelInput);

library.add(faPhone, faHome, faFile, faDatabase, faAddressBook, faQuestion, faPowerOff, faPencilAlt, faGift, faPlus, faSmile, faUser, faArrowRight, faQuestionCircle, faForward, faCheck, faClipboard, faWallet, faClock, faBriefcase, faMoneyBill, faUsers, faServer, faArrowRight, faCaretDown, faQuestionCircle, faHome, faBars, faArrowRight, faEnvelope, faPowerOff, faLocationArrow, faBell, faAngleDown, faCircle, faArrowLeft)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    AOS.init()
  }
}).$mount("#app");
