import Vue from "vue";
import Vuex from "vuex";
import User from './modules/user';
import Requests from './modules/requests';

// load vuex
Vue.use(Vuex);

// create store
export default new Vuex.Store({
  modules: {
    User,
    Requests
  }
});
