<template>
  <div class="main-sec">
    <!-- topbar -->
    <!-- hero section -->

    <div class="hero">
      <div class="hero-info">
        <h1 class="hero-info-text1">Path to
            <span class="blue" id="blue">
            <vue-typer :text='profAnim' :type-delay='120' erase-delay='70' class="vue-typer" style="color:blue" ></vue-typer>
            </span> <br>
          through
          <strong>Therapy</strong>
          
          <!-- <span class="blue" id="blue">
            <vue-typer :text='profAnim' :type-delay='120' erase-delay='70' class="vue-typer" style="color:blue" ></vue-typer>
            </span> <br> -->
        </h1>
        <span class="hero-info-text2">Connect with licensed therapists from the comfort of your home. Discover a convenient and confidential way to prioritize your mental health and well-being. Your journey to a happier, healthier you starts here.
        </span>
        <a href="https://98zh7mjvgx0.typeform.com/to/inAW1YKg" class="btn1">
          <span class="btn1-text">Get Started</span>
        </a>
      </div>
      <div class="hero-images">
        <!-- <div class="image-flex">
               <div class="image-grid"> <img :src="getImgUrl(hero_img1)" alt="" width="150px" class="hero-img" />
          <img :src="getImgUrl(hero_img2)"  alt="" width="150px" class="hero-img" /></div>
             <div class="image-flex2">  <img :src="getImgUrl(hero_img3)"  alt="" width="150px" class="hero-img" ></div>
             <div class="image-grid2">           <img :src="getImgUrl(hero_img4)" alt="" width="150px" class="hero-img">
             <img :src="getImgUrl(hero_img5)"  alt="" width="150px" class="hero-img"></div>
             </div> -->

        <div class="image-flex">
          <img src="../assets/group1.png" alt="" width="403px">
        </div>
      </div>

      <!-- hero images mobile -->
      <div class="hero-images-mob">
        <img src="../assets/group1.png" alt="" width="300px" class="mob-image">
      </div>
    </div>



    <!-- second section -->
    <div class="sec2">
      <h1 class="sec2-text1">HOW DOPAWELL WORKS</h1>
<!-- 
      <div class="sec2-btn-sec">
        <div class="sec2-btn-sec-left" :style="[showFac1 ? { 'background-color': '#00ad14', 'color': 'white' } : {}]"
          @click="changeViewFac()">For Facilities</div>
        <div class="sec2-btn-sec-right" :style="[showProf1 ? { 'background-color': '#00ad14', 'color': 'white' } : {}]"
          @click="changeViewProf()">For Professionals</div>
      </div> -->


      <div class="sec2-main-grid profs" v-if="showProf1">
        <div class="sec2-main-grid-item">
          <div class="sec2-main-grid-text1"> Personalized Assessment</div>
          <span class="sec2-main-grid-text2">Fill out a customized assessment to pinpoint your unique requirements.</span> <br> <br>
        </div>
        <div class="sec2-main-grid-item">
          <div class="sec2-main-grid-text1">Assessment Analysis</div>
          <span class="sec2-main-grid-text2">Our system carefully analyzes your assessment to determine the most suitable therapy approach for your individual needs.</span> <br> <br>
        </div>
        <div class="sec2-main-grid-item">
          <div class="sec2-main-grid-text1"> Match with Your Preferred Therapist</div>
          <span class="sec2-main-grid-text2">Connect with your chosen therapist and start your sessions.2 </span>
        </div>
      </div>

      <div class="sec2-main-grid facs" v-if="showFac1">
        <div class="sec2-main-grid-item">
          <div class="sec2-main-grid-text1">Sign Up</div>
          <span class="sec2-main-grid-text2">Create your profile, customize your
            dashboard and manage your preferences. </span> <br><br>
        </div>
        <div class="sec2-main-grid-item">
          <div class="sec2-main-grid-text1">Search Candidates</div>
          <span class="sec2-main-grid-text2">Search for verified professionals in the pool based on profession, location,
            years of experience, urgency etc.</span>
          <br><br>
        </div>
        <div class="sec2-main-grid-item">
          <div class="sec2-main-grid-text1">Hire a Professional</div>
          <span class="sec2-main-grid-text2">Chat with candidates, schedule and conduct
            your video interviews all on our platform and hire a professional. </span>
        </div>
      </div>

    </div>


    <!-- Banner section (3) -->

  </div>
</template>


<style scoped>
* {
  font-family: 'Gotham' sans-serif;
  /* font-family: 'Raleway', sans-serif; */
}

.hero {
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 20px;
  /* background-image: url("../assets/banner-images/bg-gradient1.png");
  background-size: 100% 727px; */
  height: 727px;
}

.hero-info {
  display: flex;
  flex-direction: column;
  padding-left: 94px;
  padding-top: 144px;
}

.hero-info-text1 {
  font-size: 78px;
  margin-bottom: 30px;
  line-height: 72px;
  font-weight: 400;
  line-height: 1;
}

.hero-images {
  padding-top: 72px;
  /* margin-right: 94px; */
}

.hero-images-mob {
  display: none;
}

.image-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  position: relative;
  top: 100px;
  right: 120px;
}

.image-flex2 {
  display: flex;
  justify-content: center;
}


/* .image-grid{
  display: grid;
  grid-template-columns: 50% 50%;
}

.image-grid2{
  display: grid;
  grid-template-columns: 50% 50%;
  margin-left: 70px;
  margin-right: 70px;
}

.hero-img{
  display: flex;
  justify-content: center;
  margin: 0 auto;
} */

.blue {
  color: #00ad14;
}

#blue {
  color: #00ad14;
}

.hero-info-text2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 50px;
  padding-right: 100px;
  color: rgb(44, 43, 43);
  font-weight: 300;
}

.btn1 {
  background-color: #00ad14;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;

  position: static;
  width: 251px;
  height: 60px;
  left: 0px;
  top: 314px;
}

.btn1:hover {
  color: black;
  border: 1px solid black;
  background-color: white;
}

.btn1-text {
  text-align: center;
}

.hero-img2 {
  position: relative;
  left: 250px;
}

.hero-img3 {
  position: relative;
  top: 150px;
  right: 100px;
}

.hero-img4 {
  position: relative;
  top: 300px;
  right: 50px;
}

.hero-img5 {
  position: relative;
  top: 150px;
  left: 50px;
}

.sec2 {
  height: 425px;
  margin: 94px;
  padding: 40px;
  padding-top: 48px;
  margin-top: 50px;
  background-color: #F5F5F5;
  border-radius: 5px;
}


.sec2-btn-sec {
  border: 1px solid #DADADA;
  padding: 0px;
  margin: 20px auto;
  width: 380px;
  display: grid;
  grid-template-columns: 50% 50%;
  border-radius: 8px;
}

.sec2-btn-sec-left {
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 38px;

  position: static;
  width: 168px;
  height: 40px;
  left: 4px;
  top: 4px;
}

.sec2-btn-sec-right {
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 28px;
  position: static;
  width: 179px;
  height: 40px;
  left: 180px;
  top: 4px;

  border-radius: 8px;
}

.active1 {
  background-color: #00ad14;
  color: white;
}

.sec2-text1 {
  text-align: center;
  color: #00ad14;
  font-weight: 700;
}

.sec2-main-grid {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  margin: 50px auto;
  line-height: 26px;
}

.sec2-main-grid-item {
  margin: 30px;
}

.sec2-main-grid-text1 {
  font-weight: 700;
  margin-bottom: 20px;
}

.sec2-main-grid-text2 {
  font-weight: 300;
}

.banner1 {
  height: 478px;
  width: 1253px;
  padding: 100px;
  margin: 0 auto;
  background-image: url("../assets/banner-images/blue-banner.png");
  color: white;
}

.banner1-text1 {
  font-size: 56px;
  font-weight: 700;
  padding-right: 60px;
  line-height: 66px;
}

.banner1-text2 {
  font-size: 25px;
  font-weight: lighter;
  line-height: 35px;
}

.waitlist-sec {
  background-image: url("../assets/banner-images/waitlist.png");
  background-size: 1440px 768px;
  margin: 40px auto;
  padding: 40px;
  height: 768px;
  width: 1440px;
  padding-top: 130px;
}

.waitlist-sec-text1 {
  margin-left: 60px;
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
}

.waitlist-sec-grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin-left: 30px;
  margin-top: 40px;
}

.waitlist-sec-card1 {
  margin: 20px;
  background-color: #F7F7F7;
  z-index: 3;
  border-radius: 8px;
  box-shadow: 0px 6px 6px -6px rgba(0, 0, 0, 0.52), 0px 0px 1px rgba(0, 0, 0, 0.4);
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  /* Auto Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 42px 20px 46px 30px;
  position: static;
  width: 326px;
  height: 442.02px;
  left: 0px;
  top: 0px;

}

.waitlist-sec-card-text1 {
  margin-top: 34px;
  font-weight: 700;
  line-height: 31px;
}

.waitlist-sec-flex-item {
  margin-top: 11px;
  line-height: 26px;
}

.circle-icon {
  width: 5px;
}

.illus1 {
  width: 110.88px;
  height: 90.02;
}

.waitlist-sec-card2 {
  margin: 20px;
  margin-left: 40px;
  background-color: #F7F7F7;
  z-index: 3;
  border-radius: 8px;
  box-shadow: 0px 6px 6px -6px rgba(0, 0, 0, 0.52), 0px 0px 1px rgba(0, 0, 0, 0.4);
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  /* Auto Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 42px 20px 44px 30px;
  position: static;
  width: 320px;
  height: 441.62px;
  left: 362px;
  top: 0px;
}

.waitlist-sec-card1:hover {
  border: 3px solid #83c3ff;
}

.waitlist-sec-card2:hover {
  border: 3px solid #83c3ff;
}

.illus2 {
  width: 110.88px;
  height: 90.02;
}

.waitlist-sec-card-text1 {
  font-size: 22px;
}

.waitlist-sec-flex {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-left: 15px;
}

.waitlist-sec-btn {
  background-color: #00ad14;
  color: white;
  border: none;
  margin-top: 11px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;

  position: static;
  width: 251px;
  height: 60px;
  left: 0px;
  top: 314px;
}

.waitlist-sec-btn:hover {
  border: 1px solid black;
  background-color: white;
  color: black;

}

.sec4 {
  background-color: #EDF3F9;
  border-radius: 10px;
  /* make this to be 50% transparent */
  max-width: 1264px;
  height: 634px;
  margin: 94px auto;
  padding: 20px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.sec4-text1 {
  margin-top: 33px;
  font-size: 48px;
  line-height: 72px;
  font-weight: 700;
  text-align: center;
}

.sec4-text2 {
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  margin-left: 300px;
  margin-right: 300px;
  margin-top: 33px;
}

.sec4-btn {
  background-color: #00ad14;
  color: white;
  border: none;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  margin: 0 auto;
  margin-top: 33px;
  transition: 0.2s all ease-in-out;
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  position: static;
  width: 251px;
  height: 60px;
  left: 416.5px;
  top: 190px;
}

.sec4-btn:hover {
  color: black;
  background-color: white;
}

.sec4-caro-image {
  max-width: 862px;
}

.sec4-caro-image-sec {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 33px;
}

.sec5 {
  text-align: center;
  font-size: 34px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 100px;
}

.sec5-bold {
  font-weight: 700;
}

.email-link {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.email-link:hover {
  color: #00ad14;
}


vue-typer {
  color: #00ad14 !important;
  display: inline-block
}

vue-typer .custom.char.typed {
  color: #00ad14 !important;
  display: inline-block
}

vue-typer .custom.char.selected {
  color: #00ad14 !important;
  display: inline-block
}

::v-deep .typed {
  color: #00ad14 !important;
}


@media screen and (max-width: 800px) {

  .hero {
    display: flex;
    flex-direction: column;
    padding: 3px;
    background-image: none;
    height: 450px;
  }

  .hero-info {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-top: 60px;
  }

  .hero-info-text1 {
    font-size: 41px;
    margin-bottom: 30px;
    line-height: 46px;
  }

  .hero-info-text2 {
    padding-right: 40px;
    font-size: 16px;
  line-height: 26px;
  margin-bottom: 50px;
  padding-right: 100px;
  color: rgb(44, 43, 43);
  font-weight: 300;
  }

  .hero-images {
    display: none;
  }

  .hero-images-mob {
    margin-top: 50px;
    position: relative;
    display: flex;
    justify-content: center;
  }


  .image-flex {
    display: none;
  }

  .sec2 {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    height: 570px;
    margin: 30px auto;
    padding: 20px;
    margin-top: 400px;
    background-color: #F5F5F5;
    border-radius: 5px;
  }

  .sec2-text1 {
    font-size: 20px;
    margin-top: 20px;
  }

  .sec2-main-grid-text1 {
    margin-bottom: 5px;
  }

  .sec2-main-grid-text2 {
    position: relative;
    bottom: 5px;
  }



  .sec2-btn-sec {
    width: 305px;
    margin: 0px auto;
    margin-top: 20px;

  }

  .sec2-btn-sec-left {
    font-size: 13px;
    position: static;
    width: 140px;
    height: 40px;
    left: 2px;
    top: 4px;
    padding: 0px;
    /* padding: 8px 18px; */
  }

  .sec2-btn-sec-right {
    font-size: 13px;
    position: static;
    width: 140px;
    height: 40px;
    right: 2px;
    top: 4px;
    padding: 0px;
    /* padding: 8px 18px; */
  }

  .sec2-main-grid {
    display: flex;
    flex-direction: column;
  }

  .sec2-main-grid-item {
    margin: 2px;
    font-size: 14px;
  }


  .btn1 {
    width: 251px;
  }

  .banner1 {
    height: 660px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 15px;
    padding-top: 70px;
    background-image: url("../assets/banner-images/ver-blue.png");
    border-radius: 15px;
    background-repeat: no-repeat;
  }

  .banner1-text1 {
    font-size: 29px;
    font-weight: 700;
    padding-right: 20px;
    line-height: 46px;
  }

  .banner1-text2 {
    font-size: 23px;
    font-weight: lighter;
    margin-top: 40px;
    padding-right: 20px;
    line-height: 35px;
  }

  .waitlist-sec {
    background-color: #F7F7F7;
    background-size: 1440px 768px;
    padding: 4px;
    height: 1150px;
    width: 100%;
    padding-top: 50px;
    margin: 0 auto;
    margin-top: 40px;
  }

  .waitlist-sec-text1 {
    margin-left: 0px;
    text-align: center;
    font-weight: 700;
    font-size: 37px;
  }

  .waitlist-sec-grid {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }


  .waitlist-sec-card1,
  .waitlist-sec-card2 {
    background-color: #F7F7F7;
    margin: 20px auto;
  }

  .sec4 {
    background-color: #DAE6F2;
    /* make this to be 50% transparent */
    width: 100%;
    height: 700px;
    padding: 5px;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .sec4-text1 {
    margin-top: 50px;
    font-size: 37px;
    line-height: 57px;
    font-weight: 700;
    text-align: center;
  }

  .sec4-text2 {
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 40px;
  }

  .sec4-caro-image-sec {
    overflow: scroll;
  }

  .sec5 {
    text-align: center;
    font-size: 24px;
  }
}

/* Gotham font 

  font-family: 'Gotham', sans-serif;
  font-family: 'Gotham Black', sans-serif;
  font-family: 'Gotham Light', sans-serif;
  font-family: 'Gotham Thin', sans-serif;
  font-family: 'Gotham XLight', sans-serif;
  font-family: 'Gotham Book', sans-serif;
  font-family: 'Gotham Ultra', sans-serif; */


/* Transition */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

/* @keyframes bounce {
  from {margin-top: 1px;}
  to {margin-top: -1px;}
} */
</style>


  ::v-deep .typed {
    color: #fff !important;
  }

<script>
import gsap from "gsap";
import { VueTyper } from 'vue-typer'

const profs = ["Wellness", "Health", "Strength"];
// "Physiotherapist"
export default {
  name: "Home",

  components: {
    VueTyper
  },

  data: function () {
    return {
      prof: "",
      profAnim: "",
      profNum: 0,
      showFac1: false,
      showProf1: true,
      images: ["img1", "img2", "img3", "img4", "img5"],
      image: 'https://picsum.photos/id/1005/600/200',
      hero_img1: '',
      hero_img2: '',
      hero_img3: '',
      hero_img4: '',
      hero_img5: '',
      num1: 0,
      num2: 1,
      num3: 2,
      num4: 3,
      num5: 4

    };
  },

  methods: {
    updateProf: function () {
      this.prof = profs[this.profNum];
      if (this.profNum <= 2) {
        this.profNum++;
      }
      if (this.profNum > 2) {
        this.profNum = 0;
      }
    },

    //  updateImg: function() {

    //    // image1
    //   this.hero_img1 = this.images[this.num1];
    //   if(this.num1 <= 4){
    //     this.num1++;
    //   }
    //   if(this.num1 > 4){
    //     this.num1 = 0;
    //   }

    //      // image2
    //   this.hero_img2 = this.images[this.num2];
    //   if(this.num2 <= 4){
    //     this.num2++;
    //   }
    //   if(this.num2 > 4){
    //     this.num2 = 0;
    //   }

    //      // image3
    //   this.hero_img3 = this.images[this.num3];
    //   if(this.num3 <= 4){
    //     this.num3++;
    //   }
    //   if(this.num3 > 4){
    //     this.num3 = 0;
    //   }

    //      // image4
    //   this.hero_img4 = this.images[this.num4];
    //   if(this.num4 <= 4){
    //     this.num4++;
    //   }
    //   if(this.num4 > 4){
    //     this.num4 = 0;
    //   }

    //      // image5
    //   this.hero_img5 = this.images[this.num5];
    //   if(this.num5 <= 4){
    //     this.num5++;
    //   }
    //   if(this.num5 > 4){
    //     this.num5 = 0;
    //   }
    // },

    // getting the hero images through this method (webpack recommended)
    getImgUrl(img) {
      var image = require.context('../assets/hero-images/', false, /\.png$/)
      return image('./' + img + ".png")
    },

    changeViewProf() {
      console.log('showing it')
      this.showFac1 = false;
      this.showProf1 = true;
    },

    changeViewFac() {
      console.log('showing it')
      this.showFac1 = true;
      this.showProf1 = false;
    }
  },

  mounted: function () {
    this.updateProf();
    setInterval(this.updateProf, 2500);

    //   this.updateImg();
    // setInterval(this.updateImg, 1000);

    const script = document.createElement("script");
    script.src = "https://js-eu1.hs-scripts.com/25346287.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        // check these id's
        window.hbspt.forms.create({
          portalId: "your-portal-id",
          formId: "your-form-id",
          target: "#hubspotForm"
        })
      }
    })




  },

  watch: {
    prof: function (newValue) {
      gsap.to(this.$data, { x: 500, duration: 0.5, profAnim: newValue });
    }
  }
};


</script>
