<template>
    <div class="main">

        <!-- left section -->
        <div class="main-left">
            <div class="main-left-con">
                    <h1 class="main-text1">Get Started</h1>
            <div class="main-text2">Select an option to indicate your status</div>


        <!-- cards section -->
        <div class="cards-sec">
            <!-- card 1 -->
            <div  @click="changeShow()" class="card1"  :style="[showProf ? {'border': '#00ad14', 'color': 'red'} : {}]">
              <font-awesome-icon
        :icon="['fa', 'user']"
        class="user-icon"
      />
             <h1 class="card-text1">I am healthcare Professional</h1>
             <h3 class="card-text2">I want to use loocum to find opportunities.</h3>
            </div>

            <!-- card2 -->
             <div @click="changeShow()" class="card2" :style="[showFac ? {'border': '#00ad14'} : {}]">
               <font-awesome-icon
        :icon="['fas', 'user']"
        class="building-icon"
      />
             <h1 class="card-text1">I am healthcare Professional</h1>
             <h3 class="card-text2">I want to use loocum to find opportunities.</h3>
            </div>
        </div>

        <div class="btn-sec">
             <router-link to="signup/professional" class="main-btn" v-if="showProf" >Register</router-link>
         <router-link to="signup/facility" class="main-btn btn-right" v-if="showFac">Register</router-link>
        </div>

       
    

        <h1 class="main-left-text2">Healthcare Work Begins Here</h1>
            </div>
        </div>

        <!-- right section -->
        <div class="main-right">

            <h1>Perks at a glance</h1>
            <div>
                <div class="main-right-text">- Find your next role with zero stress</div>
                 <div class="main-right-text">- Free to set up, no financial commitment is required from you.</div>
                  <div class="main-right-text">- Showcase all the skills that make you stand out.</div>
                   <div class="main-right-text">- Manage all your activities from your dashboard, whether it’s interviewing with a potential employer or being onboarded to a new workplace.</div>
                    <div class="main-right-text">- Get support 24/7, that’s why we are here.</div>
            </div>

        </div>
    </div>
</template>


<style scoped>
@import url('http://fonts.cdnfonts.com/css/gotham');


.main{
    display: grid;
    grid-template-columns: 70% 30%;
    font-family: 'Gotham' sans-serif;

}

.main-left{
    background-color: #EDF3F9;
}

.main-left-con{
   display: flex;
   flex-direction: column;
   margin: 90px auto;
   width: 600px;

}

.main-text1{
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 34px;
    color: #000000;
    text-align: left;

}

.main-text2{
font-family: Gotham;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 28px;
display: flex;
align-items: center;
color: #2C2A3B;
}

.cards-sec{
    display: flex;
    flex-direction: row;
}

.card1, .card2{
    position: static;
width: 246px;
height: 229px;
left: 0px;
top: 0px;
margin: 10px;
background: #FFFFFF;
border: 1px solid blue;
cursor: pointer;
/* LOC Blue/$-LOC Blue-500 */

/* border: 2px solid #2C6EEC; */
box-sizing: border-box;
/* Light/shadow-lg */

box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
border-radius: 6px;
padding: 20px;
}

.user-icon, .building-icon{
    font-size: 50px;
    margin-bottom: 20px;
}


.main-btn{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 12px;
width: 245px;
height: 48px;
background: #218634;
border-radius: 8px;
color: white;
text-decoration: none;
margin-top: 20px;
}

.main-btn:hover{
    background-color: white;
    color: black;
    border: 1px solid black;
}

.btn-sec{
    display: grid;
   grid-template-columns: 50% 50%;
}

.btn-right{
    margin-left: 270px;
}

.card-text1{
    font-family: Gotham;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 28px;
/* or 175% */
display: flex;
align-items: center;
/* LOC/Main/Body-Text */
color: #2C2A3B;
}

.card-text2{
/* Web/Text Extra Small */

font-family: Gotham;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 24px;
/* or 171% */

display: flex;
align-items: center;

/* LOC/Main/Body-Text */

color: #2C2A3B;

}

.main-left-text2{
margin-top: 60px;
    font-family: Gotham;
font-style: normal;
font-weight: bold;
font-size: 41px;
line-height: 50px;
/* or 122% */

display: flex;
align-items: center;

/* LOC/Main/Primary */

color: #00ad14;
border-bottom:  2px solid #2C6EEC;
width: 600px;
}

.main-right{
    color: white;
    background-image: url('../../assets/signup/bg1.png');
    /* background-size: 300px 500px; */
    min-height: 95vh;
    padding: 20px;
}

.main-right-text{
    line-height: 22px;
    margin-top: 20px;
}

</style>

<script>


export default {
  name: "",
  components: {
 
  },

    data: function() {
    return {
      showProf: true,
      showFac: false
    };

    // button to change 
  },

  methods: {
    changeShow(){
        this.showProf = !this.showProf;
        this.showFac = !this.showFac;
    }
  }
};
</script>
