<template>
  <div class="main-sec">

    <!-- hero section -->
    <div class="hero">
      <h1 class="main-text">Who we are</h1>
      <!-- <div class="sub-text">One thing you should know about us at loocum is that we are passionate about Healthcare. With years of experience in the medical sector, we know firsthand that the professionals that put in the day-to-day work are strong pillars of a great healthcare system. We also understand how costly and challenging it can be for facilities to fill vacancies and open shifts when they arise.</div> -->
      <div class="grid1">
        <div class="flex1">
            <img src="../assets/about/illus/illus1.png" alt="" width="150px" class="flex1-image">
            <div class="flex1-text">We are a team of ambitious people who are passionate about healthcare. </div>
        </div>
        <div class="flex1">
           <img src="../assets/about/illus/illus2.png" alt="" width="150px" class="flex1-image">
            <div class="flex1-text">We have years of experience working in the healthcare industry.</div>
        </div>
        <div class="flex1">
           <img src="../assets/about/illus/illus3.png" alt="" width="150px" class="flex1-image">
            <div class="flex1-text">We know firsthand that healthcare professionals are strong pillars of a great healthcare system.</div>
        </div>
        <div class="flex1">
           <img src="../assets/about/illus/illus4.png" alt="" width="150px" class="flex1-image">
            <div class="flex1-text">We understand the challenges that healthcare facilities face when vacancies and open shifts arise.</div>
        </div>
      </div>
    </div>

    <div class="sec2">
      <h1 class="sec2-text1">Our mission is simple.</h1>
      <div class="sec2-text2">To harness technology in mobilising a workforce of qualified healthcare professionals that healthcare facilities can readily access whenever they need help thereby saving cost and time, while simultaneously providing a seamless professional networking experience for African healthcare workers.</div>
    </div>

   <div class="sec3">

       <img src="../assets/about/img3.png" alt="" width="150px" class="img-banner">
     <h1 class="sec3-text1">We are building the networking hub for healthcare labour in Africa.</h1>
      <a href="https://s68gvei6dz3.typeform.com/to/TH2Sphz0" class="btn1">
        <span class="btn1-text">Join the waitlist</span>
  </a>
    </div>


    <div class="sec5">
    <img src="../assets/illus/email.png" alt="" width="50px" >
  Got any questions?
  <span class="sec5-bold"> Email us at 
     <a class="email-link" href="mailto:hello@loocum.com">
        hello@loocum.com
       </a>
   </span>
</div>
  </div>
</template>


<style scoped>

@import url('http://fonts.cdnfonts.com/css/gotham');
*{
  font-family: 'Gotham' sans-serif;
  /* font-family: 'Raleway', sans-serif; */
}


.hero{
  height: 500px;
  padding: 100px;
}

.main-text{
    margin-top: 40px;
    font-size: 58px;
    line-height: 79px;
    font-weight: 700;
    text-align: center;
    color: #00ad14;
}

.grid1{
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin-top: 40px;
  
}

.flex1{
  display: flex;
  flex-direction: column;
  margin: 40px;
}

.flex1-text{
  text-align: center;
  line-height: 33px;
  font-size: 16px;
  font-weight: 400;

}

.flex1-image{
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.sec2-text1{
  color: #00ad14;
  font-weight: 700;
  font-size: 58px;
  text-align: center;
  margin-top: 152px;
}

.sec2-text2{
  text-align: center;
  margin-top: 25px;
  padding-left: 300px;
  padding-right: 300px;
  line-height: 39px;
  font-size: 16px;
  font-weight: 400;
}

.sec3{
  height: 1158px;
  background-image: url('../assets/about/bg1.png');
  background-size: 100% 1158px;
  padding-top: 100px;
  margin-top: 52px;
}

.img-banner{
  width: 1132px;
  margin: 0 auto;
    display: flex;
  justify-content: center;
}

.sec3-text1{
  font-weight: 700;
  font-size: 58px;
  text-align: center;
  margin: 100px;
}

.btn1{
  margin: 0 auto;
  background-color: #00ad14;
  width: 251px;
  height: 60px;
  color: white;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  text-decoration: none;
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  position: static;
  left: 416.5px;
  top: 190px;
}

.btn1:hover{
  background-color: white;
  color: black;
  border: 1px solid black;
}

.sec5{
  text-align: center;
  font-size: 34px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 100px;
  margin-top: 100px;
}

.sec5-bold{
 font-weight: 700;
}

.email-link{
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.email-link:hover{
  color: #00ad14;
}

@media screen and (max-width: 800px) {

  .hero{
  height: 1000px;
  padding: 5px;
}

.main-text{
  font-size: 38px;
}

  .grid1{
    display: flex;
    flex-direction: column;
  }

  .flex1{
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.sec2-text1{
  color: #00ad14;
  font-weight: 700;
  font-size: 38px;
  text-align: center;
  margin-top: 152px;
}

.sec2-text2{
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

.sec3{
  height: 700px;
  background-image: none;
  background-size: 351px 151px;
  padding-top: 100px;
  margin-top: 52px;
}

.img-banner{
  width: 351px;
  margin: 0 auto;
    display: flex;
  justify-content: center;
}

.btn1{
  margin-top: 50px;
}

.sec3-text1{
  font-weight: 700;
  font-size: 38px;
  line-height: 59px;
  margin: 10px;
}

.sec5{
  text-align: center;
  font-size: 24px;
  margin: 20px;
  margin-bottom: 100px;
  margin-top: 50px;
}

}
</style>

<script>


export default {
  name: "",
  components: {
 
  },
};
</script>
