<template>
  <div class="main-sec">

      <div class="hero">
        <h1 class="text1">Frequently asked questions</h1>
      </div>

       <div class="sec2">
      <div class="question-box" v-b-toggle.collapse-1 >
        What is the purpose of the pre-assessment?
             <font-awesome-icon
        :icon="['fas', 'angle-down']"
        class="angle_icon"
      />
        </div>

  <b-collapse id="collapse-1" class="mt-2">
   <div class="answer-box">
    The pre-assessment helps us understand your specific needs and preferences, allowing us to match you with the most suitable therapist and therapy approach.  </div>
  </b-collapse>

 
      <div class="question-box" v-b-toggle.collapse-2>
        How do I connect with my therapist after the assessment process?
               <font-awesome-icon
        :icon="['fas', 'angle-down']"
        class="angle_icon"
      />
        </div>

        <b-collapse id="collapse-2" class="mt-2">
   <div class="answer-box">
    Once your therapy recommendation is finalized, we will connect you with your preferred therapist, and you can begin your therapy sessions through our teletherapy platform.
  </div>
  </b-collapse>

      <div class="question-box" v-b-toggle.collapse-3>
        Is my assessment information kept confidential?
              <font-awesome-icon
        :icon="['fas', 'angle-down']"
        class="angle_icon"
      />
        </div>


        <b-collapse id="collapse-3" class="mt-2">
   <div class="answer-box">
    Yes, your assessment information is kept strictly confidential and is only used to provide you with the best therapy options.
  </div>
  </b-collapse>

      <div class="question-box" v-b-toggle.collapse-5>
        What technology do I need for teletherapy sessions?
              <font-awesome-icon
        :icon="['fas', 'angle-down']"
        class="angle_icon"
      />
        </div>

          <b-collapse id="collapse-5" class="mt-2">
   <div class="answer-box">
    You'll need a device with a camera and microphone (e.g., a smartphone, tablet, or computer) and a stable internet connection to participate in teletherapy sessions</div>
  </b-collapse>

      <div class="question-box" v-b-toggle.collapse-6>
        How do I provide feedback on my therapy experience?
              <font-awesome-icon
        :icon="['fas', 'angle-down']"
        class="angle_icon"
      />
        </div>

           <b-collapse id="collapse-6" class="mt-2">
   <div class="answer-box">
    We encourage you to share your feedback with us to help improve our services. You can provide feedback through our platform or contact our support team.
       </div>
  </b-collapse>

    </div>

  <div class="sec5">
    <img src="../assets/illus/email.png" alt="" width="50px" >
  Got any questions?
  <span class="sec5-bold"> Email us at 
     <a class="email-link" href="mailto:hello@loocum.com">
        hello@dopawell.com
       </a>
   </span>
</div>

  </div>
</template>


<style scoped>
@import url('http://fonts.cdnfonts.com/css/gotham');

*{
  font-family: 'Gotham' sans-serif;
  /* font-family: 'Raleway', sans-serif; */
}


.hero{
  background-color: #00ad14;
  color: white;
  width: 100vw;
  height: 298px;
}

.text1{
 text-align: center;
 padding-top: 120px;
 font-weight: 700;
 color: white;
 font-size: 50px;
}

.sec2{
  background-color: #F5F5F5;
  padding-top: 30px;
  padding-bottom: 30px;
}

.question-box{
  width: 1000px;
  padding: 16px;
  margin:0 auto;
  margin-top: 20px;
  border: none;
  font-size: 24px;
  line-height: 38px;
  font-weight: 500;
  transition: all 0.5s ease-in-out;
  text-align: justify;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
}

.question-box:hover{
 color: #00ad14;
}

.answer-box{
  width: 1000px;
  color: black;
  background-color: white;
  padding: 25px;
  margin:0 auto;
  margin-top: 20px;
  border-radius: 10px;
   font-size: 18px;
  line-height: 34px;
}

.sec5{
  text-align: center;
  font-size: 34px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 100px;
  margin-top: 100px;
}

.sec5-bold{
 font-weight: 700;
}

.email-link{
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.email-link:hover{
  color: #00ad14;
}

@media screen and (max-width: 800px) {

.question-box{
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  font-size: 18px;
   line-height: 24px;
}

.answer-box{
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  font-size: 18px;
   line-height: 34px;
}

.text1{
 font-size: 30px;
padding-top: 60px;
}

.hero{
  height: 198px;
  padding: 10px;
}

.sec5{
  text-align: center;
  font-size: 24px;
}
}


</style>

<script>


export default {
  name: "",
  components: {
 
  },
};
</script>
