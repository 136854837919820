<template>
    <div class="main"> 
    
     <div class="form-card">
        <h1 class="form-card-text1" v-if="stage1 || stage2">Create an Account</h1>
        <div class="form-card-text2" v-if="stage1 || stage2">Already have an account? Login </div>

        <div class="form-progress-con" v-if="stage1 || stage2">
          <div class="form-progress1" :style="[stage1 ? {'background-color': '#4EFF03'} : {}]"></div>
          <div class="form-progress2" :style="[stage2 ? {'background-color': '#4EFF03'} : {}]"></div>
          <div class="form-progress3" :style="[stage3 ? {'background-color': '#4EFF03'} : {}]"></div>
        </div>

          <div class="provide-text">provide your details below</div>
<!-- facilty details  -->
<!-- stage 1 -->
        <div class="form-section" v-if="stage1">
          stage 1
            <div class="form-group">
                <label for="" class="form-card-label">Health Facility Name</label> <br>
                <input type="text" class="form-card-input" placeholder="Enter facility name" v-model="fac_name">
            </div>
                <div class="form-group">
                <label for="" class="form-card-label">Facility Contact Number</label> <br>
                <input type="text" class="form-card-input" placeholder="234 803 540 3456" v-model="fac_phone">
            </div>
                <div class="form-group">
                <label for="" class="form-card-label">Facility email address</label> <br>
                <input type="text" class="form-card-input" placeholder="Email address" v-model="fac_email">
            </div>

                <div class="form-group">
                <label for="" class="form-card-label">Where is your facility located?</label> <br>
                <input type="text" class="form-card-input" placeholder="Select state" v-model="fac_loc">
            </div>

              <div class="form-group">
                <label for="" class="form-card-label">Valid Address of Facility</label> <br>
                <input type="text" class="form-card-input" placeholder="Enter address of facility" v-model="fac_add">
            </div>

            <div class="input-grid">
              <input type="text" class="form-card-input-sm">
              <input type="text" class="form-card-input-sm">
            </div>
   <button class="form-btn" @click="changeStage1()">continue</button>
            </div>


<!-- stage 2 -->
  <div class="form-section" v-if="stage2">
          Facility representative details
               <div class="input-grid">
              <input type="text" class="form-card-input-sm" placeholder="First name" v-model="rep_first_name">
              <input type="text" class="form-card-input-sm" placeholder="Last name" v-model="rep_last_name">
            </div>
                <div class="form-group">
                <label for="" class="form-card-label">Facility Contact Number</label> <br>
                <input type="text" class="form-card-input" placeholder="234 803 540 3456" v-model="rep_phone">
            </div>
                <div class="form-group">
                <label for="" class="form-card-label">Email address</label> <br>
                <input type="text" class="form-card-input" placeholder="Email address" v-model="rep_email">
            </div>

                <div class="form-group">
                <label for="" class="form-card-label">Gender</label> <br>
                <input type="text" class="form-card-input" placeholder="Select state" v-model="rep_gen">
            </div>

                 <div class="form-group">
                <label for="" class="form-card-label">How did you hear about us?</label> <br>
                <input type="text" class="form-card-input" placeholder="Select state" v-model="state">
            </div> 
   <button class="form-btn" @click="changeStage2()">Submit</button>
            </div>


<!-- stage 3 -->
<div v-if="stage3">
  Your request is being processed, please check your email.
</div>
 
<!-- facility representative details  -->

<div>

     <!-- <div class="form-section">
            <div class="form-group">
                <label for="">First name</label> <br>
                <input type="text" class="form-card-input" placeholder="Enter facility name" v-model="name">
            </div>
                <div class="form-group">
                <label for="">Last name</label> <br>
                <input type="text" class="form-card-input" placeholder="234 803 540 3456" v-model="phone">
            </div>
                <div class="form-group">
                <label for="">Email address</label> <br>
                <input type="text" class="form-card-input" placeholder="Email address" v-model="email">
            </div>

                <div class="form-group">
                <label for="">Current role</label> <br>
                <input type="text" class="form-card-input" placeholder="Select state" v-model="state">
            </div>

              <div class="form-group">
                <label for="">How did you hear about us?</label> <br>
                <input type="text" class="form-card-input" placeholder="Enter address of facility" v-model="address">
            </div>

            </div> -->

</div>           

         
<div>By creating an account, you agree to loocum </div>
<div>Terms & Conditions and Privacy Policy</div>

        </div>

    </div>


</template>


<style scoped>
@import url('http://fonts.cdnfonts.com/css/gotham');

.main{
    background-color: #00ad14;
    min-height: 100vh;
}

.bg-1{
    background-image: url('../../assets/signup/form/bg-top.png');
    height: 300px;
    background-size: 500px 300px;
}

.form-card{
    background-color: #FCFCFC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 800px; 
    padding: 60px;
    position: absolute;
    top: 10%;
    margin-left: 60%;
    border-radius: 9px;
    /* left: 50%; */

}

.form-card-text1{
    color:#00ad14;
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 34px;
    width: 313px;
}

.provide-text{
font-family: Gotham;
font-size: 16px;
line-height: 28px;
/* identical to box height, or 175% */
display: flex;
align-items: center;
color: #4B5563;
}

.form-progress-con{
  display: flex;
  flex-direction: row;
}

.form-progress1{
width: 93px;
height: 8px;
background: #DADADA;
margin: 10px;
}

.form-progress2{
width: 93px;
height: 8px;
background: #DADADA;
margin: 10px;
}

.form-progress3{
width: 93px;
height: 8px;
background: #DADADA;
margin: 10px;
}

.form-card-input{
   padding: 12px 16px;
   width: 300px;
   border: 1px solid #D6DDE0;
   background: #FFFFFF;
    border: 1px solid #D6DDE0;
    box-sizing: border-box;
    border-radius: 8px;
}

.form-card-label{

}

.form-grid-1{
    display: grid;
    grid-template-columns: 50% 50%;
}

.form-btn{
    width: 300px;
    height: 48px;
    left: 0px;
    top: 654px;
    background: #00ad14;
    border-radius: 8px;
    color: white;
}

.input-grid{
  display: grid;
  grid-template-columns: 50% 50%;
   width: 300px;
   grid-gap: 10px;
}

.form-card-input-sm{
   padding: 12px 16px;
   border: 1px solid #D6DDE0;
   background: #FFFFFF;
    border: 1px solid #D6DDE0;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
}


</style>



<script>
import axios from "axios";

export default {
  name: "",
  data() {
    return {
      errors: [],
      fac_name: '',
      fac_phone: '',
      fac_email: '',
      fac_loc: '',
      fac_add: '',
      fac_type: '',
      fac_emp: '',
      rep_first_name: '',
      rep_last_name: '',
      rep_phone: '',
      rep_email: '',
      rep_role: '',
      rep_hear_about: '',
      // for the stages in the form
      stage1: true,
      stage2: false,
      stage3: false
    };
  },

  methods: {

    changeStage1(){
      this.stage1 = false;
      this.stage2 = true;
      this.stage3 = false 
    },

     changeStage2(){
      this.stage1 = false;
      this.stage2 = false;
      this.stage3 = true 
    },

    submit() {
      // form validation
      this.errors = [];

      if (!this.name) {
        this.errors.push("Facility name");
      }
      if (!this.phone) {
        this.errors.push("Phone");
      }
      if (!this.email) {
        this.errors.push("Email");
      }
      if (!this.state) {
        this.errors.push("State");
      }
      if (!this.address) {
        this.errors.push("Address");
      }
      if (
        this.name &&
        this.phone &&
        this.email &&
        this.state &&
        this.address
      ) {
        const prof_info = {
            name: this.name,
          phone: this.phone,
         email: this.email,
         state: this.state,
          address: this.address,
        };

        // using axios to send data
        axios({
          method: "post",
          url: "http://localhost:3000/api/prof",
          // url: 'https://loocum.com/api/prof',
          data: prof_info,
        }).then((response) => {
          if (response.data == "success") {
            (this.showMain = false),
              (this.showSuc = true),
              console.log("i can see it");
          } else {
            console.log(response);
          }
        });

        console.log("this is the client" + prof_info);
      }
    },
  },
};
</script>
