
const state = {
  user: {},
};

const getters = {
  getUser: state => state.user
};

const actions = {

  async fetchUser({ commit }, user) {
    commit('setUser', user);
  },

};

const mutations = {
  setUser: (state, user) => (state.user = user)
  //check this
  
};

export default {
  state,
  getters,
  actions,
  mutations
};