import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Faq from "../views/Faq.vue";
import Signup from "../views/signup/Main.vue";
import profForm from "../views/signup/Prof.vue";
import facForm from "../views/signup/Fac.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },

  {
    path: "/about",
    name: "about",
    component: About,
  },

  {
    path: "/faq",
    name: "faq",
    component: Faq,
  },


  {
    path: "/signup",
    name: "signup",
    component: Signup,
  },

  {
    path: "/signup/professional",
    name: "signup-professional",
    component: profForm,
  },


  {
    path: "/signup/facility",
    name: "signup-facility",
    component: facForm,
  }




];


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
