import axios from "axios"

const state = {
  requests: []
};

const getters = {
  allReqs: state => state.requests
};

const actions = {

  async fetchReqs({ commit }, email) {
    const response = await axios.get(
      `http://localhost:3000/api/request/${email}`,
    );

    commit('setReqs', response.data.reqs);
  },

};

const mutations = {
  setReqs: (state, reqs) => (state.requests = reqs)
  
};

export default {
  state,
  getters,
  actions,
  mutations
};